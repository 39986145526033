.layout-sidebar {
    position: fixed;
    width: 300px;
    height: calc(100vh - 55px);
    z-index: 998;
    overflow-y: auto;
    user-select: none;
    top: 55px;
    left: 0rem;
    transition: transform 0.2s, left 0.2s;
    background-color: var(--secondary-color);
    padding: 1rem 1.5rem;

    .grid-menu {
        display: grid;
        grid-template-rows: 1fr 1fr;
        align-items: flex-start;
        height: 100%;
    }
}

.layout-menu {
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100%;

    .layout-root-menuitem {
        > .layout-menuitem-root-text {
            font-size: 0.857rem;
            text-transform: uppercase;
            font-weight: 700;
            color: var(--surface-900);
            margin: 0.75rem 0;
        }

        > a {
            display: none;
        }
    }

    a {
        user-select: none;

        &.active-menuitem {
            > .layout-submenu-toggler {
                transform: rotate(-180deg);
            }
        }
    }

    li.active-menuitem {
        > a {
            .layout-submenu-toggler {
                transform: rotate(-180deg);
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        a {
            display: flex;
            align-items: center;
            position: relative;
            outline: 0 none;
            color: white;
            cursor: pointer;
            padding: 0.75rem 1rem;
            border-radius: 12px;
            transition: background-color 0.2s, box-shadow 0.1s;
            margin-bottom: 5px;

            .layout-menuitem-icon {
                margin-right: 0.5rem;
            }

            .layout-submenu-toggler {
                font-size: 75%;
                margin-left: auto;
                transition: transform 0.2s;
            }

            &.active-route {
                background-color: white;
                color: var(--secondary-color);
            }

            &:hover {
                background-color: white;
                color: var(--secondary-color);
            }
        }

        ul {
            overflow: hidden;
            border-radius: 12px;

            li {
                a {
                    margin-left: 1rem;
                }

                li {
                    a {
                        margin-left: 2rem;
                    }

                    li {
                        a {
                            margin-left: 2.5rem;
                        }

                        li {
                            a {
                                margin-left: 3rem;
                            }

                            li {
                                a {
                                    margin-left: 3.5rem;
                                }

                                li {
                                    a {
                                        margin-left: 4rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.pi {
    font-size: 18px;
}

fa-icon {
    margin-left: 0.4rem;
    margin-right: 0.6rem !important;
    font-size: 16px;
}

.item-logout {
    width: 100%;
    padding: 0.5rem 0.5rem;
    margin-bottom: 10rem;
    display: flex;
    align-items: flex-end;
    height: 100%;
}
