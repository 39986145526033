* {
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: 14px;
}

body {
    margin: 0;
    padding: 0;
    min-height: 100%;
    background: #f0f0f0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
}

.layout-wrapper {
    min-height: 100vh;
}

.card {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    padding: 25px 25px 30px;
}

.icon-s1 {
    .pi {
        font-size: 0.5rem;
    }
}

.icon-s2 {
    .pi {
        font-size: 1rem;
    }
}

.icon-s3 {
    .pi {
        font-size: 1.3rem;
    }
}

.icon-s4 {
    .pi {
        font-size: 2rem;
    }
}
