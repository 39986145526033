@media screen and (max-width: 1800px) {
    .caption-feed {
        display: grid !important;
        grid-template-columns: 1fr;
        row-gap: 10px;

        .search-export {
            display: grid !important;
            grid-template-columns: 1fr 1fr 1fr 1fr 90px 90px;
            row-gap: 10px;

            background-color: red;

            .btn-primary {
                width: 100%;
            }

            .select-unit-performance {
                width: 100%;
                min-width: 250px;
            }

            .calendar-filter .p-calendar {
                width: 100%;
                min-width: 90px;
            }

            input {
                width: 100%;
                min-width: 100px;
            }

            .btn-group {
                display: flex;
            }
        }

        .search-export-2 {
            grid-template-columns: 1fr 1fr 1fr 90px 90px;
        }

        .search-export-professional {
            grid-template-columns: 1fr 1fr 90px 1fr 120px;
        }

        .search-export-admin {
            grid-template-columns: 1fr 90px 200px 120px;
        }
    }
}

@media screen and (max-width: 1650px) {
    .caption {
        display: grid !important;
        grid-template-columns: 1fr;
        row-gap: 10px;

        .search-export {
            display: grid !important;
            grid-template-columns: 1fr 1fr 1fr 1fr 90px 90px;
            row-gap: 10px;

            .btn-primary {
                width: 100%;
            }

            .select-unit-performance {
                width: 100%;
                min-width: 250px;
            }

            .calendar-filter .p-calendar {
                width: 100%;
                min-width: 90px;
            }

            input {
                width: 100%;
                min-width: 100px;
            }

            .btn-group {
                display: flex;
            }
        }

        .search-export-2 {
            grid-template-columns: 1fr 1fr 1fr 90px 90px;
        }

        .search-export-professional {
            grid-template-columns: 1fr 1fr 90px 1fr 120px;
        }

        .search-export-admin {
            grid-template-columns: 1fr 90px 200px 120px;
        }

        .search-export-settlements {
            grid-template-columns: 1fr 1fr 1fr 1fr 90px;
        }

        .search-export-settlements-2 {
            grid-template-columns: 1fr 1fr 1fr 90px;
        }
    }

    .caption-settlements {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr;

        .search-export-settlements {
            grid-template-columns: 1fr 90px;
        }

        .info-sheduled {
            justify-content: center;
        }
    }

    .view-info-details {
        display: none !important;
    }

    .view-info-details-2 {
        display: contents !important;
    }
}

@media screen and (max-width: 1650px) {
    .caption {
        .search-export {
            .select-unit-performance {
                min-width: 180px;
            }
        }
    }
}

@media screen and (max-width: 867px) {
    .p-datatable .p-datatable-header {
        padding: 1rem 0.5rem;
    }

    .content-select-professional {
        width: 100%;
    }

    .select-professional {
        .p-dropdown {
            width: 100%;
        }
    }

    .caption,
    .caption-feed,
    .caption-invoices,
    .caption-files,
    .caption-settlement-detail {
        display: grid !important;
        grid-template-columns: 1fr;
        row-gap: 10px;

        .search-export {
            display: grid !important;
            grid-template-columns: 1fr;
            row-gap: 10px;

            .btn-primary {
                width: 100%;
            }
        }

        input {
            width: 100%;
        }

        .btn-plain-primary {
            button {
                width: 100%;
            }
        }

        p-dropdown {
            .p-dropdown {
                width: 100%;
            }
        }

        .calendar-filter .p-calendar {
            width: 100%;
        }

        .btn-group {
            display: flex;
            column-gap: 6px;
            align-items: center;
            justify-content: center;

            .p-button.p-button-icon-only {
                width: 5rem;
            }

            .icon-s3 {
                .pi {
                    font-size: 1.8rem;
                }
            }
        }
    }

    .caption-colum {
        display: grid !important;
        grid-template-columns: 1fr;
        row-gap: 10px;

        input {
            width: 100%;
        }
    }

    .caption-invoices {
        .btn-group {
            display: flex;
            column-gap: 6px;
            align-items: center;
            justify-content: center;

            .p-button.p-button-icon-only {
                width: 5rem;
            }

            .icon-s3 {
                .pi {
                    font-size: 1.8rem;
                }
            }
        }
    }

    .p-datatable-wrapper {
        max-height: max-content !important;
    }
}
