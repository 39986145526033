@media screen and (min-width: 1960px) {
    .layout-main,
    .landing-wrapper {
        width: 1504px;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

@media (min-width: 1200px) {
    .layout-wrapper {
        &.layout-overlay {
            .layout-main-container {
                margin-left: 0;
                padding-left: 2rem;
            }

            .layout-sidebar {
                transform: translateX(-100%);
                left: 0;
                top: 0;
                height: 100vh;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &.layout-overlay-active {
                .layout-sidebar {
                    transform: translateX(0);
                }
            }
        }

        &.layout-static {
            .layout-main-container {
                margin-left: 300px;
            }

            &.layout-static-inactive {
                .layout-sidebar {
                    transform: translateX(-100%);
                    left: 0;
                }

                .layout-main-container {
                    margin-left: 0;
                    padding-left: 2rem;
                }
            }
        }

        .layout-mask {
            display: none;
        }
    }
}

@media (max-width: 1200px) {
    .blocked-scroll {
        overflow: hidden;
    }

    .layout-wrapper {
        .layout-main-container {
            margin-left: 0;
            // padding-left: 2rem;
            padding: 6rem 1rem 1rem 1rem;
        }

        .layout-sidebar {
            transform: translateX(-100%);
            left: 0;
            top: 0;
            height: 100vh;
            border-radius: 12px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            z-index: 9999;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
        }

        .layout-mask {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 999;
            width: 100%;
            height: 100%;
            background-color: var(--maskbg);
        }

        &.layout-mobile-active {
            .layout-sidebar {
                transform: translateX(0);
            }

            .layout-mask {
                display: block;
                animation: fadein 0.2s;
            }
        }
    }

    .card {
        padding: 10px 10px 12px;
    }
}

@media screen and (max-width: 1400px) {
    .layout-main-container {
        padding: 6rem 2rem 2rem 2rem;
    }
}

@media screen and (max-width: 1200px) {
    .table {
        p-table {
            .p-paginator {
                justify-content: flex-start;
            }

            th {
                font-size: 12px;
            }

            td {
                font-size: 12px;
            }
        }
    }
}

@media screen and (max-height: 670px) {
    .item-logout {
        height: 40%;
    }

    .btn-bottom-logout {
        position: relative !important;
        bottom: 0px !important;
    }
}
