/* You can add global styles to this file, and also import other style files */
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import 'assets/styles/layout.scss';

/* PrimeNG */
@import '../node_modules/primeng/resources/themes/lara-light-blue/theme.css';
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';


.content-module {
  background-color: white;
  padding: 20px;
  margin-top: 10px;
  box-shadow: gray;
  border-radius: 2%;
}

.button-select {
  background-color: #dfe7e3 !important;
  color: #179e5b !important;
  font-size: 14px;
  font-weight: 400;
  border-color: #dfe7e3 !important;
  border-radius: 8px !important;
}

.p-inline-message.p-inline-message-danger {
  background: rgba(255, 231, 230, 0.7);
  border: solid #ff5757;
  border-width: 0px;
  color: #ff5757;
}

.p-progress-spinner-circle {
  stroke: #eee !important;
}

.loader-2 {
  .p-progress-spinner-circle {
    stroke: var(--primary-color) !important;
  }
}

.alert.p-button {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);

  &:focus {
    box-shadow:
      0 0 0 2px #ffffff,
      0 0 0 4px var(--box-shadow-color),
      0 1px 2px 0 rgb(0, 0, 0);
  }
}

.alert-text.p-button-text {
  color: var(--primary-color);
}

.alert-text.p-button.p-button-text:not(:disabled):hover {
  background: var(--box-shadow-color-2);
}

.alert-text.p-button:focus {
  box-shadow:
    0 0 0 2px #ffffff,
    0 0 0 4px var(--box-shadow-color-2),
    0 1px 2px 0 rgb(0, 0, 0);
}

.alert-dialog {
  .p-dialog-header {
    padding: 0.75rem;
  }

  .pi {
    font-size: 32px;
  }

  .p-dialog-content {
    padding-top: 2px;
    column-gap: 10px;

    span {
      font-size: 15px;
      font-weight: 500;
      line-height: 22px;
    }
  }

  .p-button-label {
    font-size: 14px;
    font-weight: 500;
  }
}

.btn-login,
.btn-primary {
  .p-button {
    background: var(--button-color);
    border: 1px solid var(--button-color);
  }

  .p-button:focus {
    box-shadow:
      0 0 0 2px #ffffff,
      0 0 0 4px var(--box-shadow-color),
      0 1px 2px 0 rgb(0, 0, 0);
  }

  button {
    width: 100%;

    span {
      font-weight: 400;
    }
  }
}

.btn-icon-primary {
  .p-button {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
  }

  .p-button:focus {
    box-shadow:
      0 0 0 2px #ffffff,
      0 0 0 4px var(--box-shadow-color),
      0 1px 2px 0 rgb(0, 0, 0);
  }

  button {
    span {
      font-weight: 400;
    }
  }
}

.btn-danger {
  .p-button {
    background: var(--danger-color);
    border: 1px solid var(--danger-color);
  }

  .p-button:focus {
    box-shadow:
      0 0 0 2px #ffffff,
      0 0 0 4px var(--box-shadow-danger),
      0 1px 2px 0 rgb(0, 0, 0);
  }

  button {
    width: 100%;

    span {
      font-weight: 400;
    }
  }
}

.btn-size {
  button {
    width: 3rem !important;
  }
}

.btn-base {
  .p-button {
    background: var(--button-secondary);
    border: 1px solid var(--button-secondary);
  }

  .p-button:focus {
    box-shadow:
      0 0 0 2px #ffffff,
      0 0 0 4px var(--box-shadow-color-3),
      0 1px 2px 0 rgb(0, 0, 0);
  }

  button {
    width: 100%;

    span {
      font-weight: 400;
    }
  }
}

.btn-plain {
  .p-button {
    background: #fff;
    border: 1px solid #ddd;
  }

  .p-button:focus {
    box-shadow:
      0 0 0 2px #ffffff,
      0 0 0 4px rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgb(0, 0, 0);
  }

  button {
    span {
      font-weight: 400;
      color: #000;
    }

    .p-button-icon {
      font-size: 15px;
    }
  }
}

.btn-plain-primary {
  .p-button {
    background: transparent;
  }

  .p-button:focus {
    box-shadow:
      0 0 0 2px #ffffff,
      0 0 0 4px var(--box-shadow-color),
      0 1px 2px 0 rgb(0, 0, 0);
  }

  button {
    border: solid 1px var(--primary-color);
    box-shadow: none;

    span {
      font-weight: 700;
      color: var(--primary-color);
    }

    .p-button-icon {
      font-size: 15px;
    }
  }
}

.btn-plain-base {
  .p-button {
    background: transparent;
  }

  .p-button:focus {
    box-shadow:
      0 0 0 2px #ffffff,
      0 0 0 4px var(--box-shadow-color-2),
      0 1px 2px 0 rgb(0, 0, 0);
  }

  button {
    border: solid 1px var(--secondary-color);

    span {
      color: var(--secondary-color);
    }
  }
}

.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
  box-shadow: 0 0 0 0.2rem var(--box-shadow-color);
  border-color: var(--primary-color);
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem var(--box-shadow-color);
  border-color: var(--primary-color);
}

.p-inputtext:enabled:hover {
  border-color: var(--primary-color);
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #179e5b;
  background: #179e5b;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #179e5b;
  background: #179e5b;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #179e5b;
  background: #179e5b;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: 0 0 0 0.2rem #179e5b;
  border-color: #179e5b;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #179e5b;
}

.p-inline-message .p-inline-message-text {
  font-size: 12px;
  margin-left: 8px;
}

.p-inline-message {
  justify-content: flex-start;
  width: 100%;
  padding: 8px 12px;
}

.message-error {
  .p-inline-message .p-inline-message-text {
    font-size: 13px;
    margin-left: 8px;
  }

  .p-inline-message {
    padding: 10px 12px;
  }
}

.text-little {
  font-size: 10px;
}

.text-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: var(--primary-color);
}

p-password {
  h6 {
    font-size: 16px;
  }
  .p-password {
    width: 100%;

    input {
      width: 100%;
    }
  }
}

p-dropdown {
  .p-dropdown {
    width: 100%;
  }
}

.p-dropdown:not(.p-disabled):hover {
  border-color: var(--primary-color);
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem var(--box-shadow-color);
  border-color: var(--primary-color);
}

p-multiselect {
  .p-multiselect {
    width: 100%;
  }
}

.p-multiselect:not(.p-disabled):hover {
  border-color: var(--primary-color);
}

.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem var(--box-shadow-color);
  border-color: var(--primary-color);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight.p-focus {
  background: var(--box-shadow-color-2);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: var(--primary-color);
}

.form {
  input[disabled] {
    background: #ced4da;
  }

  .p-disabled {
    background: #ced4da;
  }
}

.p-datatable .p-datatable-thead > tr > th {
  background: #f8f9fa;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  color: var(--primary-color);
  background: var(--box-shadow-color-2);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--primary-color);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
  background: var(--box-shadow-color-2);
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem 1rem;
}

.table-not-button {
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 1rem 1rem;
  }

  .p-datatable .p-datatable-tbody > tr > .info-left {
    padding: 0px 0px 0px 0px !important;
    border-width: 0 0 0px 0 !important;
  }

  .p-datatable .p-datatable-tbody > tr > .info-left-sub {
    padding: 0px 0px 0px 0px !important;
    border-width: 0 0 0px 0 !important;
  }
}

.p-dialog {
  width: 400px;
}

.p-dialog {
  border-radius: 20px;
}

.dialog-custom {
  .p-dialog {
    width: 430px;
  }

  .background {
    background: #f3f3f3;
    border-radius: 20px;
  }

  .btn {
    display: flex;
    column-gap: 14px;
    justify-content: center;

    .p-button {
      width: 100%;
      padding: 0.75rem 0.25rem;

      .p-button-label {
        font-size: 14px;
      }
    }
  }
}

.dialog-custom-import {
  .p-dialog {
    width: 650px;
  }

  .background {
    background: #fff;
    border-radius: 20px;
  }

  .btn {
    display: flex;
    column-gap: 14px;
    justify-content: center;

    .p-button {
      width: 100%;
      // padding: 0.75rem 0.25rem;

      .p-button-label {
        font-size: 14px;
      }
    }
  }
}

.dialog-custom-details {
  .p-dialog {
    width: 550px;
  }
}

.dialog-custom-bulk {
  .p-dialog {
    width: 55vw;
  }
}

.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-sortable-column.p-highlight:hover,
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon,
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #179e5b;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  color: #179e5b;
}

.position-icon-clear {
  top: 18px;
  right: 12px;
  color: #94a3b8;
}

.p-progressbar {
  font-size: 12px;
  height: 18px;
}

.p-progressbar .p-progressbar-value {
  background: var(--primary-color);
}

.select-professional {
  .p-dropdown {
    width: 20rem;
  }
}

.select-unit-performance {
  .p-dropdown {
    // width: 20rem;
  }
}

.info-message {
  width: 100%;

  .p-inline-message {
    padding: 0.75rem 0.75rem;
  }

  .p-inline-message.p-inline-message-info {
    background: #dceaef;
    border: solid #000;
    border-width: 0px;
    color: #000;
  }
}

.calendar-filter {
  .p-calendar {
    width: 170px;
  }

  .p-datepicker table td > span.p-highlight {
    color: var(--primary-color);
    background: var(--box-shadow-color-2);
  }

  .p-disabled,
  .p-component:disabled {
    opacity: 0.4;
  }

  .p-calendar-clear-icon {
    right: 20px;
    margin-top: 0.5px;
  }
}

.table-tree {
  .p-tree {
    border: none;
    padding: 0;
  }

  .p-treenode-label {
    font-weight: 600;
  }

  .p-treenode-content {
    box-shadow: none;
  }
}

.info-section,
.info-total {
  padding: 0 !important;
  border-width: 0 0 0px 0 !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: 0 0 0 0.1px #179e5b;
}

.btn-bottom-logout {
  position: absolute;
  bottom: 5px;
  width: 260px;
}

.form-card {
  overflow: hidden;
  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #495057;
  }

  input {
    width: 100%;
  }

  input:disabled {
    background-color: #e2e8f0;
    border: 1px solid #ced4da;
  }

  .btn {
    display: flex;
    column-gap: 14px;
  }
}

//Especial class

.status {
  font-weight: 600;
  border-radius: 5px;
  font-size: 8px;
  width: 100%;
  padding: 5px;
  text-align: center;
  color: white;
}

.PendiendeCarga {
  @extend .status;
  background: #bcaf3c;
}

.PendienteAprobacion {
  @extend .status;
  background: #bcaf3c;
}

.CerradoSinCarga {
  @extend .status;
  background: #404040;
}

.CerradoConCargado {
  @extend .status;
  background: #404040;
}
p.form-error{
  color: #ff5757;
  font-size: 10px;
  margin:0;
  padding: 0;
}

.error-tooltip {
  background-color: #fff !important; /* Fondo rojo */
  color: #ff5757 !important; /* Texto blanco */
  border: .5px solid #ff5757 !important; /* Opcional: Borde */
}

.error-tooltip .ui-tooltip-arrow {
  border-top-color: #ff5757 !important; /* Opcional: Cambiar color de la flecha */
}

.center-content-toast{
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-content-toast .p-toast-detail{
  margin: 0;
}

.custom-select-button-comparativa .p-highlight {
  background-color: #CAF9E1 !important; /* Verde */
  color: #000 !important;
  border-color: #CAF9E1;
}
i.pi.pi-search{
  font-size: 14px;
}