.layout-main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding: 6rem 3rem 2rem 3rem;
    transition: margin-left 0.2s;
    background: #f0f0f0;
}

.layout-main {
    flex: 1 1 auto;
}

.table {
    p-table {
        .p-paginator {
            justify-content: flex-end;
            margin: 12px 0;
        }

        th {
            font-size: 13px;
        }

        td {
            font-size: 13px;
        }
    }
}
