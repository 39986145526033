/* General */
:root {
  --primary-color: #179e5b;
  --danger-color: #ef3340;
  --button-color: #00664f;
  --button-secondary: #64748b;
  --secondary-color: #404040;
  --box-shadow-color: #a8ddd0;
  --box-shadow-color-2: #a8ddd05e;
  --box-shadow-color-3: #64748b63;
  --box-shadow-danger: #fa959c;
}
